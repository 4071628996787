<template>
  <v-container fluid>
    <!-- formulario de declaracion c19, stepper de 4 pasos, 
    los span muestran el idioma segun la seleccion del usuario en el step 1,
    solo se traduce el formulario, el PDF sigue solo en Español -->
    <v-stepper v-model="e1">
      <!-- titulos del stepper -->
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          <span v-if="!creol">Identificación</span>
          <span v-else>ID</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2">
          <span v-if="!creol">Encuesta</span>
          <span v-else>Sondaj</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 3" step="3">
          <span v-if="!creol">Declaración</span>
          <span v-else>Deklarasyon</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">
          <span v-if="!creol">Validación</span>
          <span v-else>Validasyon</span>
        </v-stepper-step>
      </v-stepper-header>
      <!-- contenido del stepper -->
      <v-stepper-items>
        <!-- step 1 - identificacion del colaborador -->
        <v-stepper-content step="1">
          <v-card class="mb-12">
            <!-- campo de ingreso para el rut -->
            <v-col cols="12" md="4">
              <v-text-field
                v-mask="['X.XXX.XXX-X', 'XX.XXX.XXX-X']"
                v-model="rut"
                required
                prepend-inner-icon="mdi-card-account-details-outline"
                maxlength="12"
                placeholder="12.345.678-9"
              >
                <template v-slot:label>
                  <span v-if="!creol">Ingrese su rut</span>
                  <span v-else>Antre nan RUT ou</span>
                </template>
              </v-text-field>
              <!-- boton de verificacion del rut, si es válido mostrará el nombre en el cuadro siguiente, si no, lanzará la alerta -->
              <v-btn color="primary" @click="getName()">
                <span v-if="!creol">Verificar rut</span>
                <span v-else>Verifye RUT</span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="8">
              <!-- campo nombre, solo se muestra si el rut es válido -->
              <v-text-field
                v-model="nombre"
                readonly
                prepend-inner-icon="mdi-account-settings-outline"
              >
                <template v-slot:label>
                  <span v-if="!creol">Nombre colaborador</span>
                  <span v-else>Non anplwaye a</span>
                </template>
              </v-text-field>
            </v-col>
            <v-card-actions>
              <!-- boton para seguir al paso siguiente -->
              <v-btn
                :disabled="rut_conf"
                color="primary"
                @click="(e1 = 2), generateFilename()"
              >
                <span v-if="!creol">Siguiente</span>
                <span v-else>Swivan</span>
              </v-btn>
              <v-spacer></v-spacer>
              <!-- selector de idioma español/creole -->
              <v-switch v-model="creol" inset color="grey">
                <template v-slot:label>
                  <span v-if="!creol">Wè an kreyòl</span>
                  <span v-else>Ver en español</span>
                </template>
              </v-switch>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <!-- aviso en caso de c19 positivo anterior -->
        <v-dialog v-model="status_c19">
          <v-alert border="right" colored-border type="error" elevation="2">
            <span v-if="!creol">
              Lamentablemente usted no puede realizar este trámite, ya que aun
              no ha presentado la documentacion necesaria para ser rehabilitado
              su ingreso a nuestras dependencias, por favor regularice su
              situacion.</span
            >
            <span v-else>
              Malerezman ou pa ka pote soti nan pwosedi sa a, depi ou pa gen
              ankò prezante dokiman ki nesesè yo dwe reyabilite revni ou nan
              depandans nou yo, tanpri regilarize sitiyasyon ou.</span
            >
          </v-alert>
        </v-dialog>
        <!-- aviso en caso de rut erroneo o inexistente -->
        <v-dialog v-model="status_rut">
          <v-alert border="right" colored-border type="warning" elevation="2">
            <span v-if="!creol">
              Error!<br /><br />Rut ingresado inválido o no registrado en base
              de datos, por favor contáctese con su jefatura.</span
            >
            <span v-else>
              Erè!<br /><br />RUT antre valab oswa ou pa anrejistre nan baz done
              a, tanpri kontakte katye jeneral ou.</span
            >
          </v-alert>
        </v-dialog>
        <!-- step 2 - preguntas c19 -->
        <v-stepper-content step="2">
          <v-card class="mb-12">
            <span v-if="!creol">
              Por favor responda las siguientes preguntas:
              <br /><br />
              Dentro de las últimas 24 horas, ¿Ha tenido contacto con alguna
              persona confirmada de coronavirus(COVID19), y que se encuentre en
              su periodo de cuarentena?</span
            >
            <span v-else>
              Tanpri reponn kesyon sa yo:
              <br /><br />
              Nan dènye 24 èdtan yo, Èske ou te gen kontak ak yon moun konfime
              soti nan koronavirus (COVID19), epi ki moun ki nan peryòd karantèn
              li?</span
            >
            <!-- en el selector variable respuesta y selecthint son computadas, los 3 selectores son iguales-->
            <v-select
              v-model="pregunta1"
              :items="respuesta"
              :placeholder="selecthint"
              @change="sel1 = 1"
            >
            </v-select>
            <v-divider></v-divider>
            <span v-if="!creol">
              Dentro de las últimas 24 horas, ¿Ha presentado dos o mas de los
              siguientes síntomas?.
              <br />Pérdida del gusto, pérdida del olfato, perdida del apetito,
              tos o estornudos, fiebre mayor a 37,8°C, calofrios, dolor de
              cabeza, dolor de garganta, dificultad para respirar, respiracion
              agitada, congestion nasal, náuseas, vómitos, diarrea, fatiga,
              dolor de pecho, dolor muscular(no asociado a actividad física)
              <br
            /></span>

            <span v-else>
              Nan dènye 24 èdtan yo, èske ou te gen de oswa plis nan sentòm sa
              yo?
              <br />Pèt gou, pèt sant, pèdi apeti, ouse oswa etènye, lafyèv pi
              gran pase 37.8 °C, kalofye, maltèt, gòj fè mal, souf kout, respire
              souf, nen bouche, kè plen, vomisman, dyare, fatig, doulè pwatrin,
              doulè nan misk (ki pa asosye avèk aktivite fizik) <br
            /></span>

            <v-select
              v-model="pregunta2"
              :items="respuesta"
              :placeholder="selecthint"
              @change="sel2 = 1"
            >
              <template v-slot:placeholder> </template>
            </v-select>
            <v-divider></v-divider>
            <span v-if="!creol">
              ¿Usted o alguien de su grupo familiar directo (con quienes
              comparte vivienda), se encuentra a la espera de resultados de
              exámen PCR?</span
            >
            <span v-else>
              Èske ou menm oswa yon moun ki soti nan gwoup fanmi imedyat ou (ak
              ki moun ou pataje yon kay), èske ou ap tann rezilta tès PCR
              la?</span
            >
            <v-select
              v-model="pregunta3"
              :items="respuesta"
              :placeholder="selecthint"
              @change="sel3 = 1"
            >
            </v-select>
            <v-card-actions>
              <!-- boton volver atras -->
              <v-btn color="primary" @click="e1 = 1">
                <span v-if="!creol">Atrás</span>
                <span v-else>Dèyè</span>
              </v-btn>
              <v-spacer></v-spacer>
              <!-- boton para seguir al paso siguiente -->
              <v-btn
                :disabled="selconf"
                color="primary"
                @click="
                  e1 = 3;
                  checkstatus();
                "
              >
                <span v-if="!creol">Siguiente</span>
                <span v-else>Swivan</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <!-- step 3 - declaracion de responsabilidad -->
        <v-stepper-content step="3">
          <v-card class="mb-12">
            <v-checkbox v-model="confirmacion">
              <template v-slot:label>
                <div v-if="!creol">
                  <p class="text--primary">
                    Declaro que toda la información entregada es verídica, y que
                    en caso de comprobarse falsedad en los datos entregados, la
                    empresa podrá tomar todas las acciones legales que
                    correspondan.
                  </p>
                </div>
                <div v-else>
                  <p class="text--primary">
                    Mwen deklare ke tout enfòmasyon yo bay yo se verite, e ke
                    nan evènman an nan fo nan done yo bay yo, konpayi an ka pran
                    tout aksyon ki koresponn legal yo.
                  </p>
                </div>
              </template>
            </v-checkbox>
            <v-card-actions>
              <!-- boton volver atras -->
              <v-btn color="primary" @click="e1 = 2">
                <span v-if="!creol">Atrás</span>
                <span v-else>Dèyè</span>
              </v-btn>
              <v-spacer></v-spacer>
              <!-- boton para seguir al paso siguiente -->
              <v-btn
                :disabled="!confirmacion"
                color="primary"
                @click="(e1 = 4), newRespuesta()"
              >
                <span v-if="!creol">Siguiente</span>
                <span v-else>Swivan</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <!-- step 4 - resultado y descarga PDF -->
        <v-stepper-content step="4">
          <v-card class="mb-12">
            <!-- muestra advertencia en caso de que la respuesta indique sospecha de c19 -->
            <!-- deshabilitado a peticion de gerencia -->
            <span v-if="status">
              <span v-if="!creol">
                Estimado(a): {{ nom_corto }},<br />
                A continuación podrá descargar su declaración en PDF,
                <p style="color:red">
                  <b
                    >esta declaración será válida desde {{ dia_hora_ }} hasta
                    {{ dia_hora }}</b
                  >
                </p>
                .</span
              >
              <span v-else>
                Chè: {{ nom_corto }},<br />
                Ou ka telechaje deklarasyon ou an PDF,
                <p style="color:red">
                  <b
                    >Deklarasyon sa a pral valab soti nan {{ dia_hora_ }} rive
                    {{ dia_hora }}</b
                  >
                </p>
                .</span
              >
              <v-row class="pa-6">
                <v-img
                  max-width="100"
                  :src="require('../assets/check.png')"
                ></v-img>
                <v-qrcode :value="qode" level="Q" size="100" renderAs="svg" />
              </v-row>
            </span>
            <!-- para activar la advertencia, debe comentarse este span y habilitar el siguiente -->
            <span v-else>
              <span v-if="!creol">
                Estimado(a): {{ nom_corto }},<br />
                A continuación podrá descargar su declaración en PDF,
                <p style="color:red">
                  <b
                    >esta declaración será válida desde {{ dia_hora_ }} hasta
                    {{ dia_hora }}</b
                  >
                </p>
                .</span
              >
              <span v-else>
                Chè: {{ nom_corto }},<br />
                Ou ka telechaje deklarasyon ou an PDF,
                <p style="color:red">
                  <b
                    >Deklarasyon sa a pral valab soti nan {{ dia_hora_ }} rive
                    {{ dia_hora }}</b
                  >
                </p>
                .</span
              >
              <v-row>
                <v-img
                  max-width="100"
                  :src="require('../assets/check.png')"
                ></v-img>
                <v-qrcode :value="qode" level="Q" size="100" renderAs="svg" />
              </v-row>
            </span>
            <!-- <span v-else>
              <span v-if="!creol">Lamentablemente su condición de salud no permite el ingreso a nuestra planta, 
            para poder volver a ingresar deberá acudir a un centro de salud y verificar que no se encuentra contagiado con coronavirus(COVID19).
            <br>Este evento ha sido informado de forma automática al departamento de Prevención de Riesgos.
            <br>A continuación podrá descargar su declaración, la que deberá presentar junto al documento del centro médico al que asista para justificar los 
            días que no pueda concurrir al trabajo debido a esta situación.</span>
            <span v-else>
              <span v-else>Malerezman kondisyon sante ou pa pèmèt antre nan plant nou an,
            Yo nan lòd yo re-antre, ou dwe ale nan yon sant sante ak verifye ke ou pa enfekte ak koronavirus (COVID19).
            <br> Evènman sa a te rapòte otomatikman bay depatman Prevansyon Risk.
            <br> Lè sa a, ou ka telechaje deklarasyon ou an, ki ou dwe soumèt ansanm ak dokiman an nan sant medikal la ou ale nan jistifye
            jou ou pa ka ale nan travay akòz sitiyasyon sa a.</span>
            <v-img max-width="150" :src="require('../assets/alert.png')"></v-img>
            </span> -->
            <!-- <vue-qrcode errorCorrectionLevel="H" :value="qode" /> -->
            <!-- <v-qrcode :value="qode" level="Q" size="100" renderAs="svg"/> -->
            <br />
            <v-card-actions>
              <v-btn color="success" @click="genpdf()">
                <span v-if="!creol">Descargar documento</span>
                <span v-else>Telechaje dokiman an</span> </v-btn
              ><v-spacer></v-spacer>
              <v-btn color="primary" @click="(e1 = 1), newDeclarar()">
                <span v-if="!creol">Finalizar</span>
                <span v-else>Finalize</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- " -->
    <div id="pdf" class="d-none" ref="document">
      <div class="grid">
        <div>
          <img width="120" :src="require('../assets/logo-letras_negras.png')" />
        </div>
        <!-- <div> -->
        <h3>Declaración Diaria C19 Aríztia</h3>
        <!-- </div> -->
        <div>
          <img width="80" :src="status_img" />
        </div>
      </div>
      Mediante la presente {{ nombre }}, Rut: {{ rut }} declara lo siguiente:
      <br />
      <br />
      <v-icon>mdi-chevron-right</v-icon> {{ pregunta1_ }} tenido contacto con
      algun paciente diagnosticado y en cuarentena por coronavirus (COVID19)
      <br />
      <v-icon>mdi-chevron-right</v-icon> {{ pregunta2_ }} presentado sintomas
      que puedan asociarse a enfermedad por coronavirus (COVID19)
      <br />
      <v-icon>mdi-chevron-right</v-icon> Dentro de su circulo familiar directo
      {{ pregunta3_ }} personas a la espera de resultados PCR por sospecha de
      coronavirus (COVID19)
      <br />
      <!-- rehabilitar esto cuando se autorice la restriccion de acceso -->
      <!-- <br>Por consiguiente, y según los datos suministrados por el colaborador,{{confirmacion1}} se autoriza el ingreso a planta{{confirmacion2}}. -->
      <br />
      <br />La presente declaración será válida desde
      <b>{{ dia_hora_ }}</b> hasta <b>{{ dia_hora }}</b
      >.
      <br />
      <br />
      <br />
      <br />El colaborador acepta que en caso de comprobarse falsedad en los
      datos entregados, la empresa podrá tomar todas las acciones legales que
      correspondan.
      <br />
      <br />
      <!-- <hr> -->
      Si se requiere validar esta declaración, escanear el siguiente código QR o
      ingresar a {{ qode }}
      <br />
      <!-- <vue-qrcode errorCorrectionLevel="H" :value="qode" /> -->
      <v-qrcode :value="qode" level="L" size="150" renderAs="svg" />
    </div>
  </v-container>
</template>

<script>
import html2pdf from "html2pdf.js";
import C19DataService from "../services/C19DataService";
export default {
  name: "Home",

  data() {
    return {
      creol: false,
      e1: 1,
      rut: "",
      nombre: "",
      nom_corto: "",
      rut_conf: true,
      status: true,
      pregunta1: "",
      sel1: 0,
      pregunta2: "",
      sel2: 0,
      pregunta3: "",
      sel3: 0,
      confirmacion: false,
      filename: "",
      status_c19: false,
      status_rut: false,
      status_img: "",
      ceco: "",
      nom_ceco: "",
      area: "",
    };
  },
  computed: {
    qode: function() {
      return `https://c19ariztia.apidev.info/viewpdf/${this.filename}`;
    },
    fecha_: function() {
      const fechahor = this.filename.split("_");
      const validez = new Date(Number(fechahor[1]));
      return `${validez.getDate()}-${new String(
        validez.getMonth() + 1
      ).padStart(2, "00")}-${validez.getFullYear()}`;
    },
    dia_hora: function() {
      const fechahor = this.filename.split("_");
      if (fechahor[1]) {
        const validez = new Date(Number(fechahor[1]) + 43200000);
        if (this.creol) {
          return `${validez.getDate()}-${new String(
            validez.getMonth() + 1
          ).padStart(2, "00")}-${validez.getFullYear()} nan ${new String(
            validez.getHours()
          ).padStart(2, "00")}:${new String(validez.getMinutes()).padStart(
            2,
            "00"
          )} èdtan.-`;
        } else {
          return `${validez.getDate()}-${new String(
            validez.getMonth() + 1
          ).padStart(2, "00")}-${validez.getFullYear()} a las ${new String(
            validez.getHours()
          ).padStart(2, "00")}:${new String(validez.getMinutes()).padStart(
            2,
            "00"
          )} hrs.-`;
        }
      } else {
        const validez = new Date(Date.now() + 43200000);
        return `${validez.getDate()}-${new String(
          validez.getMonth() + 1
        ).padStart(2, "00")}-${validez.getFullYear()} a las ${new String(
          validez.getHours()
        ).padStart(2, "00")}:${new String(validez.getMinutes()).padStart(
          2,
          "00"
        )}hrs.-`;
      }
    },
    dia_hora_: function() {
      const fechahor = this.filename.split("_");
      if (fechahor[1]) {
        const validez = new Date(Number(fechahor[1]));
        if (this.creol) {
          return `${validez.getDate()}-${new String(
            validez.getMonth() + 1
          ).padStart(2, "00")}-${validez.getFullYear()} nan ${new String(
            validez.getHours()
          ).padStart(2, "00")}:${new String(validez.getMinutes()).padStart(
            2,
            "00"
          )} èdtan`;
        } else {
          return `${validez.getDate()}-${new String(
            validez.getMonth() + 1
          ).padStart(2, "00")}-${validez.getFullYear()} a las ${new String(
            validez.getHours()
          ).padStart(2, "00")}:${new String(validez.getMinutes()).padStart(
            2,
            "00"
          )} hrs.`;
        }
      } else {
        const validez = new Date(Date.now());
        return `${validez.getDate()}-${new String(
          validez.getMonth() + 1
        ).padStart(2, "00")}-${validez.getFullYear()} a las ${new String(
          validez.getHours()
        ).padStart(2, "00")}:${new String(validez.getMinutes()).padStart(
          2,
          "00"
        )}hrs.`;
      }
    },

    respuesta: function() {
      if (this.creol) {
        return [
          { text: "Wi", value: true },
          { text: "Pa", value: false },
        ];
      } else {
        return [
          { text: "Si", value: true },
          { text: "No", value: false },
        ];
      }
    },
    selecthint: function() {
      if (this.creol) {
        return `Tanpri chwazi yon opsyon...`;
      } else {
        return `Seleccione una opción...`;
      }
    },
    selconf: function() {
      if (this.sel1 == 1 && this.sel2 == 1 && this.sel3 == 1) {
        return false;
      } else {
        return true;
      }
    },
    pregunta1_: function() {
      if (this.pregunta1) {
        return "Ha";
      } else {
        return "No ha";
      }
    },
    pregunta2_: function() {
      if (this.pregunta2) {
        return "Ha";
      } else {
        return "No ha";
      }
    },
    pregunta3_: function() {
      if (this.pregunta3) {
        return "tiene";
      } else {
        return "no tiene";
      }
    },
    confirmacion1: function() {
      if (!this.status) {
        return " no";
      } else {
        return "";
      }
    },
    confirmacion2: function() {
      if (!this.status) {
        return " hasta que el colaborador verifique su estado de salud en el recinto de salud que le corresponda";
      } else {
        return "";
      }
    },
  },
  methods: {
    getName() {
      //obtener nombre del colabot
      var rut = this.rut.replace(/\./g, "").toUpperCase(); //formatear rutpara consulta
      C19DataService.getRut(rut) //enviar consulta al endpoint de getrut
        .then((response) => {
          if (response.data[0].estado_c19) {
            //si estadoc19 es "true" entonces restringir el acceso al formulario
            // descomentar lo siguiente para rehabilitar la alerta de restriccion de emision de certificado
            // this.status_c19 = response.data[0].estado_c19
            // borrar lo siguiente para rehabilitar la restriccion de acceso
            this.rut_conf = false; //habiliotar boton siguiente >>disable=false
            this.nombre = response.data[0].nom_comp; //cargar nombre en cuadro de texto
            this.ceco = response.data[0].ceco;
            this.nom_corto = response.data[0].nom_corto;
            this.nom_ceco = response.data[0].nom_ceco;
            this.area = response.data[0].area ? response.data[0].area : "Otras";
          } else {
            this.rut_conf = false; //habiliotar boton siguiente >>disable=false
            this.nombre = response.data[0].nom_comp; //cargar nombre en cuadro de texto
            this.ceco = response.data[0].ceco;
            this.nom_ceco = response.data[0].nom_ceco;
            this.nom_corto = response.data[0].nom_corto;
            this.area = response.data[0].area ? response.data[0].area : "Otras";
          }
        })
        .catch((e) => {
          console.log(e);
          // console.log("este error puede usarse");
          this.status_rut = true;
        });
    },

    checkstatus() {
      if (!this.pregunta1 && !this.pregunta2 && !this.pregunta3) {
        this.status = true;
        this.status_img = require("../assets/check.png");
      } else {
        this.status = false;
        this.status_img = require("../assets/alert.png");
      }
    },

    generateFilename() {
      var rut = this.rut.replace(/\./g, "").toUpperCase();
      var fecha = Date.now();
      this.filename = `${rut}_${fecha}`;
    },

    newRespuesta() {
      const fechahor = this.filename.split("_");
      const validez = new Date(Number(fechahor[1]));

      let data = {};
      data["val"] = process.env.VUE_APP_PASSWORD;
      data["filename"] = this.filename;
      data["rut"] = this.rut.toUpperCase();
      data["nombre"] = this.nombre;
      data["pregunta1"] = this.pregunta1;
      data["pregunta2"] = this.pregunta2;
      data["pregunta3"] = this.pregunta3;
      data["confirmacion"] = this.status;
      data["dia_fecha"] = this.fecha_;
      data["fecha"] = new Date(validez);
      data["ceco"] = this.ceco;
      data["nom_ceco"] = this.nom_ceco;
      data["area"] = this.area;

      C19DataService.newResp(data);
      // .then((result) => {console.log(result)})

      let data2 = {};
      data2["filename"] = this.filename;
      data2["val"] = process.env.VUE_APP_PASSWORD;
      data2["rut"] = this.rut.replace(/\./g, "").toUpperCase();
      data2["estado_c19"] = this.status ? false : true;
      data2["ult_decl"] = new Date(validez);

      C19DataService.updateRut(data2);
    },

    genpdf() {
      html2pdf(this.$refs.document, {
        margin: [0, 0.8, 0, 0.7],
        filename: this.filename,
        image: { type: "jpg", quality: 0.95 },
        html2canvas: { dpi: 192, letterRendering: true, imageTimeout: 600 },
        jsPDF: {
          unit: "in",
          format: "letter",
          orientation: "portrait",
          compress: true,
        },
      });
    },

    newDeclarar() {
      this.rut = "";
      this.nombre = "";
      this.rut_conf = true;
      this.status = true;
      this.pregunta1 = "";
      this.sel1 = 0;
      this.pregunta2 = "";
      this.sel2 = 0;
      this.pregunta3 = "";
      this.sel3 = 0;
      this.confirmacion = false;
      this.filename = "";
      this.creol = false;
    },
  },
  mounted() {
    console.log(navigator.userAgent);
  },
};
</script>
<style scoped>
#pdf {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 60px;
}

.grid {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  margin: 0 0 1rem 0;
}

.grid > div {
  height: 120px;
  /* background: red; */
  min-width: 10px;
}

body {
  margin: 1rem;
}
</style>
